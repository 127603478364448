import React from 'react'
import Hero from './Hero'
import FeatureHome from './FeatureHome'
import TeamHome from './TeamHome'
import TestimonialsHome from './TestimonialsHome'

export default function Home() {
  return (
    <div> 
      <Hero/>
      <FeatureHome/>
      <TeamHome/>
      <TestimonialsHome/>
    </div>
  )
}
