import React, { useState, useEffect } from 'react';
import worksoulslogo from "../images/workSoulsLogoWhite.png";
import worksoulslogoDark from "../images/workSoulsLogo.png";
import { NavLink, useLocation } from 'react-router-dom';

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { pathname } = useLocation();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Scroll to top when pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            {/* Navbar Start */}
            <header id="topnav" className="defaultscroll sticky">
                <div className="container">
                    {/* Logo container*/}
                    <a className="logo" href="/" style={{ padding: 20 }}>
                        <span className="logo-light-mode">
                            <img src={worksoulslogoDark} style={{ width: 200 }} className="l-dark" alt="logo-dark" />
                            <img src={worksoulslogo} style={{ width: 200 }} className="l-light" alt="logo-light" />
                        </span>
                        <img src="images/logo-light.png" className="logo-dark-mode" alt="logo" />
                    </a>
                    {/* End Logo container*/}
                    <div className="menu-extras" style={{ padding: 20 }}>
                        <div className="menu-item">
                            {/* Mobile menu toggle */}
                            <button className="navbar-toggle" id="isToggle" style={{background: 'Transparent'}} onClick={toggleMenu}>
                                <div className="lines">
                                    <span />
                                    <span />
                                    <span />
                                </div>
                            </button>
                            {/* End mobile menu toggle */}
                        </div>
                    </div>
                    <ul className="buy-button list-inline mb-0">
                        <li className="list-inline-item search-icon mb-0">
                            <div className="dropdown">
                                <button type="button" className="btn btn-link text-decoration-none dropdown-toggle mb-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    <i className="uil uil-search h5 text-dark nav-light-icon-dark mb-0" />
                                    <i className="uil uil-search h5 text-white nav-light-icon-white mb-0" />
                                </button>
                                <div className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-4 py-0" style={{ width: 300 }}>
                                    <form className="p-4">
                                        <input type="text" id="text" name="name" className="form-control border bg-white" placeholder="Search..." />
                                    </form>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div id="navigation" style={{ marginTop: isMenuOpen ? '46px' : 0, transition: 'margin-top 0.8s ease' }} className={isMenuOpen ? 'open' : ''}>
                        {/* Navigation Menu */}
                        <ul className="navigation-menu nav-right nav-light" style={{ paddingTop: 20 }}>
                            <li className="has-submenu parent-parent-menu-item">
                                <NavLink to="/" activeclassname="active">Home</NavLink>
                            </li>
                            <li className="has-submenu parent-parent-menu-item">
                                <NavLink to="/about" activeclassname="active">About</NavLink>
                            </li>
                            <li className="has-submenu parent-parent-menu-item">
                                <NavLink to="/services" activeclassname="active">Services</NavLink>
                            </li>
                            <li className="has-submenu parent-parent-menu-item">
                                <NavLink to="/blogs" activeclassname="active">Blogs</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact" activeclassname="active">Contact Us</NavLink>
                            </li>
                        </ul>{/*end navigation menu*/}
                    </div>{/*end navigation*/}
                </div>{/*end container*/}
            </header>{/*end header*/}
            {/* Navbar End */}
        </>
    );
}
