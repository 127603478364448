import React from 'react';
import Amazon from '../images/Services/amazon.png'
import Shopify from '../images/Services/shopify.png'
import Etsy from "../images/Services/etsy.png"
import WebDev from "../images/Services/web-dev.png"
import SoftwareDev from "../images/Services/software-development.png"
import CSRDev from "../images/Services/CSR-Dev.png"
import AppDev from "../images/Services/app-dev.png"
import DigitalMarketing from "../images/Services/digital-marketing.png"
import Seo from "../images/Services/seo.png"
import CSRServices from "../images/Services/Csr-services.png"
import TruckDispatching from "../images/Services/truck-dispatching.png"
import TruckBrokerage from "../images/Services/truck-brokerage.png"
import WebDesign from "../images/Services/web-design.png"
import GraphicDesign from "../images/Services/graphic-design.png"
import Animation from "../images/Services/3d-animation.png"
import Max from "../images/Services/3ds-max.png"
import Multimedia from "../images/Services/3d-multimedia.png"

// Import other images...

const ServicesPageContent = () => {
    

    const AmazonDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                Are you ready to take your Amazon business to new heights? Look no further! Our expert team specializes in helping sellers like you scale their businesses on the world's largest online marketplace. Whether you're just starting out or looking to optimize your existing store, we have the tools, expertise, and resources to help you succeed.
            </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Amazon Store Management</strong><br>
                Managing an Amazon store can be overwhelming, but it doesn't have to be. Our team of experienced professionals can take care of every aspect of your store, from product listings to customer service. We'll ensure your store is always up-to-date and optimized for maximum visibility, so you can focus on growing your business.
            </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Product Listing Optimization</strong><br>
                Standing out from the competition is key to success on Amazon. Our product listing optimization services are designed to help your products get noticed. We'll create compelling product listings that highlight your unique selling points and attract customers to your store.            
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">PPC Advertising</strong><br>
                PPC advertising is a powerful tool for driving traffic to your Amazon store. Our team of experts can create targeted PPC campaigns that reach your ideal customers and drive conversions. We'll monitor your campaigns closely to ensure you get the best possible return on your investment.            
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Inventory Management</strong><br>
                Keeping track of your inventory can be a daunting task, especially as your business grows. Our inventory management services are designed to make inventory management easy and efficient. We'll help you keep track of your stock levels and ensure you never run out of stock.            
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Expertise: Our team has years of experience in Amazon selling and knows what it takes to succeed on the platform.</li>
                <li>Customized Solutions: We tailor our services to meet your specific needs and goals, ensuring you get the results you want.</li>
                <li>Dedicated Support: We're here to support you every step of the way, from setting up your store to optimizing your listings and beyond.</li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Get Started?</strong><br>
                Contact us today to learn more about how we can help you scale your business on Amazon. Let us help you unlock the full potential of the world's largest online marketplace and take your business to new heights!          
                </p>
        ` }} />
    );
    const ShopifyDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            Are you ready to bring your e-commerce vision to life? Look no further! Our team of Shopify experts is here to help you launch your dream store on one of the world's most popular e-commerce platforms. Whether you're a first-time entrepreneur or an established business looking to expand online, we have the tools, expertise, and resources to make your Shopify store a success.
            </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Store Setup</strong><br>
                Setting up a Shopify store can be a daunting task, but it doesn't have to be. Our team will work with you to set up your store, ensuring it reflects your brand and meets your unique needs. From choosing the right theme to configuring your settings, we'll take care of everything so you can focus on growing your business.
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Theme Customization</strong><br>
                Standing out from the competition is key to success on Amazon. Our product listing optimization services are designed to help your products get noticed. We'll create compelling product listings that highlight your unique selling points and attract customers to your store.            
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">App Integration</strong><br>
                Enhance your Shopify store's functionality with custom app integrations. Whether you need to add a new payment gateway, implement a loyalty program, or integrate with third-party software, our team can help you find and integrate the right apps for your business.               
                 </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Digital Marketing Strategies</strong><br>
                Launching a Shopify store is just the beginning. To drive traffic and increase sales, you need a solid digital marketing strategy. Our team of digital marketing experts will work with you to create a customized strategy that includes SEO, social media marketing, email marketing, and more. We'll help you reach your target audience and turn visitors into customers.             
                 </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Expertise: Our team has years of experience in e-commerce and knows what it takes to succeed on Shopify.</li>
                <li>Customized Solutions: We tailor our services to meet your specific needs and goals, ensuring you get the results you want.
                </li>
                <li>Dedicated Support: We're here to support you every step of the way, from setting up your store to implementing marketing strategies and beyond.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Get Started?</strong><br>
                Contact us today to learn more about how we can help you launch your dream store on Shopify. Let's turn your e-commerce vision into reality and take your business to new heights!
                </p>
        ` }} />
    );
    const EtsyDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            Are you a creative entrepreneur looking to showcase your unique products to the world? Look no further! Our Etsy services are designed to help you stand out in the crowded marketplace and elevate your shop to new heights. Whether you're just starting out or looking to enhance your existing shop, we have the expertise and resources to support you every step of the way.           
             </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Shop Setup</strong><br>
                Setting up an Etsy shop is the first step towards reaching a global audience. Our team will guide you through the setup process, ensuring your shop reflects your brand's identity and attracts your target audience. From creating a compelling shop name to choosing the right categories and listing your products, we'll help you create a shop that stands out.
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Product Photography</strong><br>
                High-quality product photography is essential for showcasing your products effectively on Etsy. Our team of photographers will work with you to capture stunning images that highlight the unique features of your products and grab the attention of potential buyers. We'll help you create a visually appealing shop that inspires confidence in your customers.               
                 </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">SEO Optimization</strong><br>
                Getting found on Etsy is crucial for driving traffic to your shop. Our SEO experts will optimize your shop and product listings with relevant keywords and tags, making it easier for buyers to find your products in search results. We'll help you increase your visibility on Etsy and attract more potential customers to your shop.     
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Social Media Marketing</strong><br>
                Social media is a powerful tool for promoting your Etsy shop and engaging with your audience. Our team will create a customized social media marketing strategy that includes content creation, posting schedules, and engagement tactics. We'll help you build a strong social media presence that drives traffic to your shop and increases your sales.          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Expertise: Our team has years of experience in e-commerce and knows what it takes to succeed on Etsy.</li>
                <li>Customized Solutions: We tailor our services to meet your specific needs and goals, ensuring you get the results you want.
                </li>
                <li>Dedicated Support: We're here to support you every step of the way, from setting up your shop to promoting your products and beyond.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Get Started?</strong><br>
                Contact us today to learn more about how we can help you elevate your Etsy shop. Let's showcase your unique products to the world and make your mark in the crowded marketplace!
                </p>
        ` }} />
    );
    const WebDevDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            Are you ready to make a lasting impression online? Look no further! Our expert web development team is here to help you take your online presence to the next level. Whether you're a small business looking to establish your brand or a large corporation in need of a website refresh, we have the skills and expertise to create a stunning website that engages your audience and drives results.
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Responsive Design</strong><br>
                In today's digital age, having a responsive website is essential. Our team specializes in creating responsive designs that look great on any device, from desktops to smartphones and tablets. We'll ensure that your website is user-friendly and accessible to all users, no matter how they choose to browse.
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">E-Commerce Solutions</strong><br>
                Looking to sell your products or services online? Our e-commerce solutions are designed to help you succeed. We'll create a secure and user-friendly online store that makes it easy for your customers to browse, shop, and checkout. From product listings to payment gateways, we'll handle every aspect of your e-commerce website so you can focus on growing your business.           
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Stunning Websites that Drive Results</strong><br>
                Your website is often the first impression customers have of your business. Our team will work with you to create a website that reflects your brand's identity and values. Whether you're looking for a sleek and modern design or something more traditional, we'll bring your vision to life on the web.
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Social Media Marketing</strong><br>
                Social media is a powerful tool for promoting your Etsy shop and engaging with your audience. Our team will create a customized social media marketing strategy that includes content creation, posting schedules, and engagement tactics. We'll help you build a strong social media presence that drives traffic to your shop and increases your sales.   
                </p>        
                 <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Expertise: Our team has years of experience in web development and knows what it takes to create a successful website.</li>
                <li>Customized Solutions: We tailor our services to meet your specific needs and goals, ensuring you get the results you want.
                </li>
                <li>Dedicated Support: We're here to support you every step of the way, from initial concept to website launch and beyond.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Get Started?</strong><br>
                Contact us today to learn more about how we can help you elevate your online presence. Let's turn your vision into a reality on the web and create a website that drives results for your business!
                </p>
        ` }} />
    );
    const SoftwareDevDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            Is your business in need of a software solution that can streamline operations and drive efficiency? Look no further! Our software development team specializes in creating custom software tailored to your specific needs. Whether you're looking for a CRM system to manage customer relationships or an enterprise application to streamline workflow processes, we have the expertise to develop software that drives your business forward.          
            </p>  
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Tailored to Your Needs</strong><br>
                Off-the-shelf software solutions can be limiting, as they may not fully meet your unique business requirements. Our custom software solutions are designed to address your specific needs, ensuring that you get the functionality you need to succeed. We'll work closely with you to understand your business processes and goals, and develop software that aligns with your vision.          
                      </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Scalable and Efficient</strong><br>
                As your business grows, so too should your software. Our team develops scalable software solutions that can grow with your business. Whether you're a small startup or a large enterprise, we'll create software that can handle your current needs and adapt to future growth.              
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">CRM Systems to Enterprise Applications</strong><br>
                From customer relationship management (CRM) systems to enterprise applications, we have experience developing a wide range of software solutions. Whether you need software to manage your sales pipeline, track inventory, or automate workflow processes, we have the expertise to create software that meets your needs.          
                </p>    
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                  <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Expertise: Our team has years of experience in software development and knows what it takes to create efficient and scalable software solutions.</li>
                <li>Customized Solutions: We tailor our software to meet your specific needs, ensuring that you get the functionality you need to succeed.
                </li>
                <li>Dedicated Support: We're here to support you every step of the way, from initial concept to software deployment and beyond.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Get Started?</strong><br>
                Contact us today to learn more about how our custom software solutions can streamline your business operations. Let's work together to develop software that drives your business forward and helps you achieve your goals!               
                 </p>
        ` }} />
    );
    const CSReDevDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            In today's ever-changing world, the role of businesses extends beyond profitability. Companies are increasingly expected to be socially responsible and contribute positively to society. At [Your Company Name], we understand the importance of Corporate Social Responsibility (CSR) and are committed to helping businesses like yours develop and implement CSR strategies that not only align with your values and goals but also make a tangible impact on the world around us.
            </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            <strong style="font-size: 14px;">Tailored CSR Strategies</strong><br>
            Every business is unique, and so are its CSR needs. That's why we take the time to understand your organization's values, goals, and culture before developing CSR strategies. Whether you're focused on environmental sustainability, community development, or employee well-being, we'll work with you to create a customized CSR plan that reflects your commitment to making a difference.            
            </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Implementation Excellence</strong><br>
                Developing a CSR strategy is just the beginning. We'll help you implement your initiatives effectively, ensuring they align with your overall business objectives. From organizing volunteer programs to partnering with local charities, we'll work alongside you to make sure your CSR efforts have a meaningful and lasting impact.
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Enhancing Brand Reputation</strong><br>
                Engaging in CSR initiatives isn't just about doing good; it's also about building a positive brand image. Our team will help you communicate your CSR efforts to your stakeholders, including customers, employees, and investors. By showcasing your commitment to social responsibility, you'll not only enhance your brand reputation but also attract like-minded customers and employees.  
                </p>     
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Measuring Impact</strong><br>
                We believe in the importance of measuring the impact of CSR initiatives. We'll provide you with regular reports on the progress of your CSR efforts, including metrics such as volunteer hours, funds raised, and community engagement. This will not only help you track the success of your initiatives but also demonstrate your commitment to transparency and accountability.
                </p>  
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Join Us in Creating a Better Tomorrow</strong><br>
                Together, we can make a difference. Contact us today to learn more about how our CSR services can help you create a brighter future for your business, your community, and the world. Let's work together to create a better tomorrow!               </p>
        ` }} />
    );
    const AppDevDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            In today's digital age, having a mobile app is essential for businesses looking to connect with their customers on the go. However, with millions of apps available in the app stores, standing out can be a challenge. That's where we come in. Our app development experts specialize in creating user-friendly and innovative mobile apps for iOS and Android that not only meet but exceed your expectations.
            </p>  
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Crafting Your Vision into Reality
                </strong><br>
                Launching a successful mobile app starts with a great idea. Our team will work closely with you to understand your vision and goals for the app. From there, we'll develop a comprehensive plan to turn your idea into a fully functional app. Our agile development process ensures that you're involved every step of the way, from concept to launch.
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">User-Friendly Design that Engages</strong><br>
                User experience is key to the success of any mobile app. Our team of designers will create a user-friendly interface that engages your audience and keeps them coming back for more. Whether you're looking for a sleek and modern design or something more playful and interactive, we'll work with you to create a design that not only looks great but also delivers a seamless user experience.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Innovation at Its Best</strong><br>
                What sets your app apart from the competition? Our team will help you identify innovative features that will make your app stand out. Whether it's integration with third-party services, social media sharing, or advanced analytics, we'll help you create an app that offers unique value to your users and keeps them engaged.
                </p>    
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                  <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Expertise: Our team has years of experience in app development and knows what it takes to create a successful mobile app.</li>
                <li>Customized Solutions: We tailor our app development services to meet your specific needs and goals, ensuring that your app exceeds your expectations.
                </li>
                <li>Dedicated Support: We're here to support you every step of the way, from initial concept to software deployment and beyond.Dedicated Support: We're here to support you every step of the way, from concept to launch and beyond. Our team is committed to your success and will work tirelessly to ensure that your app is a success in the crowded marketplace.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Launch Your App?</strong><br>
                Contact us today to learn more about how our app development services can help you create a mobile app that stands out in the crowded marketplace. Let's work together to turn your app idea into a reality!
                </p>
        ` }} />
    );
    const DigitalMarketingDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            In today's digital world, having a strong online presence is essential for business success. Whether you're a small start-up or a large corporation, our digital marketing services are designed to help you reach your target audience, drive engagement, and boost conversions. From social media management to email marketing and content creation, we'll work with you to create a customized strategy that meets your goals and exceeds your expectations.
            </p>  
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Social Media Management
                </strong><br>
                Social media is a powerful tool for connecting with your audience and building brand awareness. Our social media management services include creating and curating content, engaging with your followers, and analyzing performance metrics to ensure your social media efforts are driving results.
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Email Marketing</strong><br>
                Email marketing is a cost-effective way to reach your audience directly and drive conversions. Our email marketing services include designing and sending engaging emails, segmenting your audience for targeted campaigns, and analyzing performance to optimize your email strategy.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Content Creation</strong><br>
                Creating high-quality content is key to attracting and engaging your audience. Our content creation services include writing blog posts, creating infographics, and producing videos that resonate with your target audience and drive them to take action.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">SEO Optimization</strong><br>
                Ensuring your website is optimized for search engines is essential for driving organic traffic. Our SEO optimization services include keyword research, on-page optimization, and link building to improve your website's visibility in search engine results.
                </p>  
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">PPC Advertising</strong><br>
                PPC advertising is a highly effective way to drive targeted traffic to your website. Our PPC advertising services include creating and managing ad campaigns, optimizing ad performance, and analyzing results to maximize ROI.
                </p>   
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                  <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Expertise: Our team of digital marketing experts has years of experience in helping businesses like yours succeed online.
                </li>
                <li>Customized Strategies: We tailor our digital marketing strategies to meet your specific goals and objectives, ensuring you get the results you want.
                </li>
                <li>Dedicated Support: We're here to support you every step of the way, from strategy development to implementation and beyond.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Get Started?</strong><br>
                Contact us today to learn more about how our digital marketing services can help you take your digital presence to the next level. Let's work together to achieve your business goals and drive success online!
                </p>
        ` }} />
    );
    const SeoDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            Are you looking to increase your website's visibility in search engine results and attract more organic traffic? Look no further! Our SEO services are designed to improve your online presence and drive qualified leads to your site. With a focus on keyword research, on-page optimization, and link building, we'll help you climb the ranks and stand out in search engine results.
            </p>  
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Keyword Research
                </strong><br>
                Keywords are the foundation of any successful SEO strategy. Our team will conduct in-depth keyword research to identify the most relevant and high-performing keywords for your business. By targeting the right keywords, we'll help you attract more qualified traffic to your website and improve your search engine rankings.
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">On-Page Optimization</strong><br>
                Optimizing your website's on-page elements is crucial for SEO success. Our team will optimize your website's meta tags, headings, and content to ensure they are aligned with your target keywords. We'll also optimize your website's structure and navigation to improve user experience and make it easier for search engines to crawl and index your site.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Link Building</strong><br>
                Building high-quality backlinks is essential for improving your website's authority and rankings in search engine results. Our team will develop a custom link building strategy tailored to your business, focusing on acquiring links from reputable and relevant websites. By building a strong backlink profile, we'll help you climb the ranks and attract more organic traffic to your site.
                </p>    
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Monitoring and Reporting</strong><br>
                SEO is an ongoing process, and it's important to monitor your progress and make adjustments as needed. Our team will provide you with regular reports on your website's performance, including rankings, traffic, and conversions. We'll also conduct regular audits to identify areas for improvement and implement strategies to drive even better results.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                  <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Expertise: Our team of SEO experts has years of experience in helping businesses like yours improve their online visibility and drive qualified leads.</li>
                <li>Customized Strategies: We tailor our SEO services to meet your specific goals and objectives, ensuring you get the results you want.
                </li>
                <li>Transparent Communication: We believe in transparent communication and will keep you informed every step of the way, so you know exactly what we're doing and why.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Boost Your Online Visibility?</strong><br>
                Contact us today to learn more about how our SEO services can help you rank higher in search engine results and attract more organic traffic to your website. Let's work together to achieve your SEO goals and drive success online!
                </p>
        ` }} />
    );
    const CSRDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            Are you in search of exceptional customer service solutions? Look no further! Our call center services offer top-notch CSR services for Canada, USA, Australia, and the UK. With a strong focus on customer satisfaction, we provide multilingual support, 24/7 availability, and personalized solutions to meet your specific needs.
            </p>  
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Multilingual Support
                </strong><br>
                In today's global marketplace, providing support in multiple languages is essential. Our team of customer service representatives is fluent in multiple languages, allowing us to provide support to customers from around the world. Whether your customers speak English, French, Spanish, or any other language, we have you covered.
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">24/7 Availability</strong><br>
                Customer service should be available whenever your customers need it. That's why we offer 24/7 availability, ensuring that your customers can reach us anytime, day or night. Whether it's a question, concern, or emergency, our team is always ready to assist.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Personalized Solutions</strong><br>
                Every business is unique, and so are its customer service needs. That's why we offer personalized solutions tailored to meet your specific requirements. Whether you need help with order processing, technical support, or general inquiries, we'll work with you to develop a customized solution that meets your needs and exceeds your expectations.
                </p>    
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Focus on Customer Satisfaction</strong><br>
                At the heart of our call center services is a focus on customer satisfaction. We understand that happy customers are loyal customers, which is why we go above and beyond to ensure that every interaction is positive and helpful. From resolving issues quickly to providing friendly and professional service, we're committed to delivering exceptional customer experiences.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                  <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Experience: With years of experience in the industry, we have the knowledge and expertise to deliver top-notch customer service solutions.
                </li>
                <li>Quality: We are committed to delivering high-quality service that meets and exceeds your expectations.
                </li>
                <li>Flexibility: Our services are flexible and can be customized to meet your specific needs and budget.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Elevate Your Customer Service?</strong><br>
                Contact us today to learn more about how our call center services can help you provide exceptional customer service and improve customer satisfaction. Let's work together to take your customer service to the next level!           
                </p>
        ` }} />
    );
    const TruckDispatchingDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            Efficiently managing a fleet of trucks can be a challenging task, especially when operating across different regions. That's where our truck dispatching services come in. Whether you're operating in the USA, Canada, or the UAE, our team is here to ensure timely dispatching, route optimization, and real-time tracking, helping you streamline your operations and maximize efficiency on the road.
            </p>  
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Timely Dispatching
                </strong><br>
                One of the key aspects of fleet management is ensuring that trucks are dispatched in a timely manner. Our team uses advanced dispatching software to ensure that your trucks are dispatched promptly, helping you minimize downtime and maximize productivity.
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Route Optimization</strong><br>
                Optimizing routes is crucial for reducing fuel costs and improving overall efficiency. Our team uses advanced routing algorithms to determine the most efficient routes for your trucks, taking into account factors such as traffic, road conditions, and delivery schedules.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Real-Time Tracking
                </strong><br>
                Knowing the location of your trucks in real-time is essential for effective fleet management. Our real-time tracking system allows you to monitor the location of your trucks at all times, helping you make informed decisions and respond quickly to changing conditions.
                </p>    
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Streamlined Operations
                </strong><br>
                By outsourcing your truck dispatching needs to us, you can streamline your operations and focus on other aspects of your business. Our team will handle all aspects of dispatching, allowing you to concentrate on growing your business and serving your customers.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                  <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Experience: With years of experience in the industry, we have the knowledge and expertise to effectively manage your fleet.
                </li>
                <li>Efficiency: Our dispatching services are designed to maximize efficiency and minimize downtime, helping you save time and money.
                </li>
                <li>Customized Solutions: We understand that every business is unique, which is why we offer customized solutions tailored to your specific needs and requirements.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Optimize Your Fleet Management?</strong><br>
                Contact us today to learn more about how our truck dispatching services can help you streamline your operations and maximize efficiency on the road. Let's work together to take your fleet management to the next level!
                </p>
        ` }} />
    );
    const TruckBrokerageDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            Are you in need of reliable truck brokerage services to manage your shipments? Look no further! Our brokerage services connect you with trusted carriers in the USA, UAE, Canada, and more. From negotiating rates to managing shipments, we'll handle the logistics so you can focus on growing your business.
            </p>  
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Connecting You with Trusted Carriers
                </strong><br>
                Finding the right carrier for your shipments can be a daunting task. Our brokerage services simplify the process by connecting you with a network of trusted carriers. Whether you're shipping goods across the country or across the world, we'll help you find the right carrier for your needs.
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Negotiating Competitive Rates
                </strong><br>
                We understand that cost is a major factor in your shipping decisions. Our team will negotiate competitive rates on your behalf, ensuring that you get the best possible price for your shipments. By leveraging our relationships with carriers, we're able to secure rates that are often lower than what you could negotiate on your own.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Managing Shipments Efficiently
                </strong><br>
                Managing shipments can be a time-consuming process. Our team will handle all aspects of your shipments, from booking to delivery, ensuring that your goods arrive on time and in good condition. We'll also provide you with regular updates on the status of your shipments, giving you peace of mind knowing that your goods are in good hands.
                </p>    
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Focus on Growing Your Business
                </strong><br>
                By outsourcing your truck brokerage needs to us, you can focus on what you do best – growing your business. Our team will handle all the logistics of your shipments, allowing you to concentrate on expanding your operations and serving your customers.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                  <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Reliability: We pride ourselves on our reliability and commitment to providing excellent service to our clients.
                </li>
                <li>Experience: With years of experience in the industry, we have the knowledge and expertise to manage your shipments efficiently.
                </li>
                <li>Customized Solutions: We offer customized solutions tailored to your specific needs and requirements, ensuring that you get the services you need to succeed.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Simplify Your Shipping Process?</strong><br>
                Contact us today to learn more about how our truck brokerage services can help you manage your shipments more efficiently. Let's work together to streamline your shipping process and support the growth of your business!
                </p>
        ` }} />
    );
    const WebDesignDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            In today's digital world, having a visually appealing and user-friendly website is essential for businesses looking to stand out from the competition. At [Your Company Name], we offer web design services that are tailored to your unique needs, creating a website that not only looks great but also drives results. From concept to launch, we'll work with you to create a website that reflects your brand and engages your audience.
            </p>  
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Tailored to Your Unique Needs
                </strong><br>
                Every business is unique, and so are its web design needs. That's why we take the time to understand your brand, your target audience, and your goals before we start designing your website. Whether you're looking for a sleek and modern design or something more traditional and classic, we'll work with you to create a website that reflects your brand identity and resonates with your audience.
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Visually Appealing Design
                </strong><br>
                A visually appealing website is key to attracting and retaining visitors. Our team of designers will create a stunning design for your website that is not only visually appealing but also user-friendly. We'll ensure that your website is easy to navigate, with clear calls to action that encourage visitors to take the next step.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">User-Friendly Experience
                </strong><br>
                In addition to looking great, your website also needs to be easy to use. Our team will design your website with the user in mind, ensuring that it is intuitive and easy to navigate. Whether your visitors are accessing your site on a desktop, tablet, or smartphone, we'll create a responsive design that provides a seamless experience across all devices.
                </p>    
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Driving Results
                </strong><br>
                Ultimately, the goal of your website is to drive results for your business. Whether you're looking to generate leads, increase sales, or simply raise awareness of your brand, we'll design your website with these goals in mind. We'll optimize your website for search engines, ensuring that your target audience can find you online, and we'll track the performance of your website to ensure that it is delivering the results you need.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                  <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Experience: With years of experience in web design, we have the knowledge and expertise to create a website that meets your needs and exceeds your expectations.
                </li>
                <li>Customized Solutions: We offer customized web design solutions tailored to your unique needs, ensuring that your website stands out from the competition.
                </li>
                <li>Personalized Service: We believe in providing personalized service to every client, ensuring that you get the attention and support you need to succeed online.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Create a Stunning Website?</strong><br>
                Contact us today to learn more about how our web design services can help you create a website that sets you apart from the competition. Let's work together to create a website that drives results for your business!
                </p>
        ` }} />
    );
    const GraphicDesignDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            In the fast-paced world of design, standing out from the crowd requires creativity, precision, and a keen eye for detail. At [Your Company Name], our team of experts is proficient in industry-leading software such as Photoshop, Illustrator, Corel Draw, and InDesign, ensuring high-quality designs that captivate your audience. Whether you need graphics for print or digital media, we'll work with you to bring your vision to life with precision and creativity.
            </p>  
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Expertise in Leading Design Software
                </strong><br>
                Our team is well-versed in the latest design trends and techniques, ensuring that your designs are not only visually stunning but also in line with current industry standards. Whether you need a new logo, a brochure, a business card, or a complete branding overhaul, we have the skills and expertise to deliver exceptional results.
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Customized Designs for Every Need
                </strong><br>
                We understand that every project is unique, which is why we offer customized design solutions tailored to your specific needs and requirements. Whether you're a small business looking to establish your brand identity or a large corporation in need of a design refresh, we'll work with you to create designs that reflect your brand and resonate with your audience.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Print and Digital Design Services
                </strong><br>
                Whether you need designs for print or digital media, our team has you covered. From business cards and brochures to social media graphics and website designs, we'll create designs that are not only visually appealing but also effective in communicating your message to your audience.
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                  <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Creativity: Our team is passionate about design and is committed to pushing the boundaries of creativity to deliver designs that exceed your expectations.
                </li>
                <li>Precision: We understand the importance of precision in design, and we take great care to ensure that every element of your design is pixel-perfect.
                </li>
                <li>Client-Centric Approach: We believe in putting our clients first, and we work closely with you throughout the design process to ensure that your needs are met and your expectations are exceeded.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Elevate Your Design?</strong><br>
                Contact us today to learn more about how our design services can help you bring your vision to life. Let's work together to create designs that captivate your audience and set you apart from the competition!
                </p>
        ` }} />
    );
    const AnimationDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            In today's digital age, captivating animations can make a significant impact on your audience and help bring your ideas to life. At [Your Company Name], we offer 3D animation creation services that are perfect for commercials, presentations, and more. Using the latest tools and techniques, we'll work with you to create animations that leave a lasting impression on your audience.
            </p>  
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Perfect for Commercials and Presentations
                </strong><br>
                Whether you're looking to create a commercial for your product or service, or you need a captivating animation for a presentation, our 3D animation services are perfect for the job. Our team of expert animators will work closely with you to understand your vision and create animations that effectively communicate your message and engage your audience.
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Using the Latest Tools and Techniques
                </strong><br>
                We stay up-to-date with the latest tools and techniques in 3D animation to ensure that our animations are of the highest quality. Whether you need character animation, product animation, or architectural visualization, we have the skills and expertise to bring your ideas to life in stunning detail.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Captivating Animations that Leave a Lasting Impression
                </strong><br>
                Our goal is to create animations that not only capture the attention of your audience but also leave a lasting impression. Whether you're looking to entertain, educate, or inspire, we'll work with you to create animations that resonate with your audience and effectively convey your message.
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                  <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Expertise: Our team of animators has years of experience in creating stunning 3D animations for a variety of purposes.
                </li>
                <li>Quality: We are committed to delivering high-quality animations that meet and exceed your expectations.
                </li>
                <li>Creativity: We are passionate about animation and are always pushing the boundaries of creativity to deliver animations that are truly unique and captivating.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Bring Your Ideas to Life?</strong><br>
                Contact us today to learn more about how our 3D animation creation services can help you create animations that captivate your audience and bring your ideas to life. Let's work together to create animations that leave a lasting impression!
                </p>
        ` }} />
    );
    const MaxDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            When it comes to showcasing your vision in the best possible light, realistic 3D models and visualizations can make all the difference. At [Your Company Name], our 3D Max services are perfect for architectural rendering, product design, and more. With attention to detail and a focus on realism, we'll work with you to create 3D models that bring your ideas to life.
            </p>  
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Perfect for Architectural Rendering and Product Design
                </strong><br>
                Whether you're an architect looking to visualize a new building or a product designer looking to showcase a new product, our 3D Max services are perfect for the job. Our team of expert 3D artists will work closely with you to understand your vision and create 3D models and visualizations that exceed your expectations.
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Attention to Detail and Realism
                </strong><br>
                We understand that when it comes to 3D modeling, attention to detail and realism are key. That's why we take great care to ensure that every aspect of our 3D models is meticulously crafted to look and feel as realistic as possible. From textures and lighting to shadows and reflections, we'll create 3D models that look like they could be real.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Versatile and Customizable
                </strong><br>
                Our 3D Max services are versatile and customizable, allowing us to create 3D models for a wide range of purposes. Whether you need a single 3D model for a specific project or a series of 3D models for a larger campaign, we have the skills and expertise to deliver exceptional results.
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                  <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Experience: With years of experience in 3D modeling, we have the knowledge and expertise to create 3D models that meet and exceed your expectations.
                </li>
                <li>Quality: We are committed to delivering high-quality 3D models that are visually stunning and realistic.
                </li>
                <li>Collaborative Approach: We believe in working closely with our clients throughout the design process to ensure that their vision is brought to life exactly as they imagined.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Bring Your Ideas to Life?</strong><br>
                Contact us today to learn more about how our 3D Max services can help you create realistic 3D models and visualizations that showcase your vision in the best possible light. Let's work together to bring your ideas to life!
                </p>
        ` }} />
    );
    const MultimediaDescription = (
        <div dangerouslySetInnerHTML={{ __html: `
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
            In today's digital age, standing out in a crowded digital landscape requires creativity and innovation. At [Your Company Name], our 3D multimedia services combine creativity and technology to deliver stunning visual experiences that will take your multimedia projects to the next level. From interactive presentations to virtual tours, we'll help you stand out and captivate your audience like never before.
            </p>  
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Creativity and Technology Combined
                </strong><br>
                Our team of multimedia experts is skilled in the latest 3D technology and techniques, allowing us to create immersive and engaging experiences that captivate audiences. Whether you're looking to create a virtual tour of a property, an interactive product showcase, or a visually stunning presentation, we have the skills and expertise to bring your vision to life.
                </p>
            <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Stunning Visual Experiences
                </strong><br>
                With our 3D multimedia services, you can create stunning visual experiences that leave a lasting impression on your audience. Whether you're looking to showcase a new product, educate your audience, or tell a story, our multimedia services will help you deliver your message in a way that is engaging and memorable.
                </p> 
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Stand Out in a Crowded Digital Landscape
                </strong><br>
                In today's digital world, it's more important than ever to stand out from the competition. Our 3D multimedia services will help you do just that, allowing you to create multimedia projects that are unique, engaging, and memorable. Whether you're looking to attract new customers, educate your audience, or simply impress your stakeholders, our multimedia services will help you achieve your goals.
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                  <strong style="font-size: 14px;">Why Choose Us?</strong><br>
                <ul style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <li>Creativity: We are passionate about multimedia and are always pushing the boundaries of creativity to deliver projects that are truly unique and engaging
                </li>
                <li>Technology: We stay up-to-date with the latest 3D technology and techniques to ensure that our multimedia projects are of the highest quality.
                </li>
                <li>Collaborative Approach: We believe in working closely with our clients throughout the design process to ensure that their vision is brought to life exactly as they imagined.
                </li>
                </ul>          
                </p>
                <p style="font-size: 12px; color: #333; margin-bottom: 15px;">
                <strong style="font-size: 14px;">Ready to Take Your Multimedia Projects to the Next Level?</strong><br>
                Contact us today to learn more about how our 3D multimedia services can help you create stunning visual experiences that will captivate your audience and help you stand out in a crowded digital landscape. Let's work together to take your multimedia projects to the next level!
                </p>
        ` }} />
    );





const services = [
    {
        id: 1,
        name: "Amazon",
        image: Amazon,
        title: "Unleash Your Amazon Store's Potential with Expert Solutions",
        description: AmazonDescription
    },
    {
        id: 2,
        name: "Shopify",
        image: Shopify,
        title: "Empower Your E-Commerce Dreams with Shopify Experts",
        description: ShopifyDescription
    },
    {
        id: 3,
        name: "Etsy",
        image: Etsy,
        title: "Elevate Your Etsy Shop to Success",
        description: EtsyDescription
    },
    {
        id: 4,
        name: "Web Development",
        image: WebDev,
        title: "Elevate Your Online Presence with Stunning Web Solutions",
        description: WebDevDescription
    },
    {
        id: 5,
        name: "Software Development",
        image: SoftwareDev,
        title: "Empower Your Business with Custom Software Solutions",
        description: SoftwareDevDescription
    },
    {
        id: 6,
        name: "CSR Development",
        image: CSRDev,
        title: "Unleash Your Amazon Store's Potential with Expert Solutions",
        description: CSRDescription
    },
    {
        id: 7,
        name: "App Development",
        image: AppDev,
        title: "Transform Your App Idea into a Standout Success with Our Expert Development Services",
        description: AppDevDescription
    },
    {
        id: 8,
        name: "Digital Marketing",
        image: DigitalMarketing,
        title: "Elevate Your Online Presence with Customized Digital Marketing Solutions for Business Success",
        description: DigitalMarketingDescription
    },
    {
        id: 9,
        name: "SEO Services",
        image: Seo,
        title: "Unlock Your Website's Potential with Tailored SEO Solutions for Organic Growth",
        description: SeoDescription
    },
    {
        id: 10,
        name: "CSR Services",
        image: CSRServices,
        title: "Elevate Your Customer Service Experience with Our Call Center Solutions",
        description: CSRDescription
    },
    {
        id: 11,
        name: "Truck Dispatching",
        image: TruckDispatching,
        title: "Streamline Your Fleet Management with Expert Truck Dispatching Services",
        description: TruckDispatchingDescription
    },
    {
        id: 12,
        name: "Truck Brokerage",
        image: TruckBrokerage,
        title: "Simplify Your Shipping with Trusted Truck Brokerage Services",
        description: TruckBrokerageDescription
    },
    {
        id: 13,
        name: "Web Designing",
        image: WebDesign,
        title: "Elevate Your Online Presence with Custom Web Design Solutions",
        description: WebDesignDescription
    },
    {
        id: 14,
        name: "Graphic Design",
        image: GraphicDesign,
        title: "Unlock Your Creative Vision with Expert Design Solutions",
        description: GraphicDesignDescription
    },
    {
        id: 15,
        name: "Animation",
        image: Animation,
        title: "Crafting Captivating 3D Animations to Bring Your Ideas to Life",
        description: AnimationDescription
    },
    {
        id: 16,
        name: "3D's Max",
        image: Max,
        title: "Transforming Visions into Reality: Exceptional 3D Modeling with Attention to Detail",
        description: MaxDescription
    },
    {
        id: 17,
        name: "3D Multimedia",
        image: Multimedia,
        title: "Unleashing Creativity: Elevate Your Multimedia Projects with 3D Innovation",
        description: MultimediaDescription
    },
];
    return services;
};

export default ServicesPageContent;
