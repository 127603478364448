import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './StyleFiles/Services.css';
import Amazon from "../images/Services/amazon.png"
import Shopify from "../images/Services/shopify.png"
import Etsy from "../images/Services/etsy.png"
import WebDev from "../images/Services/web-dev.png"
import SoftwareDev from "../images/Services/software-development.png"
import CSRDev from "../images/Services/CSR-Dev.png"
import AppDev from "../images/Services/app-dev.png"
import DigitalMarketing from "../images/Services/digital-marketing.png"
import Seo from "../images/Services/seo.png"
import CSRServices from "../images/Services/Csr-services.png"
import TruckDispatching from "../images/Services/truck-dispatching.png"
import TruckBrokerage from "../images/Services/truck-brokerage.png"
import WebDesign from "../images/Services/web-design.png"
import GraphicDesign from "../images/Services/graphic-design.png"
import Animation from "../images/Services/3d-animation.png"
import Max from "../images/Services/3ds-max.png"
import Multimedia from "../images/Services/3d-multimedia.png"
import { NavLink } from 'react-router-dom';

const ServicesGrid = () => {
    // Define the styles for <h3> elements
    const h3Styles = {
        color: '#3c4858',
        fontWeight: '800',
        fontSize: '28px',
        fontFamily: 'Poppins, sans-serif',
        marginLeft: '60px',
        marginTop: '30px'
      };
    
      const h3Elements = document.querySelectorAll('h3');
      h3Elements.forEach(h3 => {
        Object.keys(h3Styles).forEach(style => {
          h3.style[style] = `${h3Styles[style]} !important`;
        });
      });
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ],
        prevArrow: <button type="button" className="slick-prev">Previous</button>,
        nextArrow: <button type="button" className="slick-next">Next</button>


    };
    
   

    return (
        <>
            <div className='container'>
                <h3>Ecommerce Services</h3>
                <Slider {...settings}>
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={Amazon} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                            <NavLink to={`/service/${1}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                            <NavLink to={`/service/${1}`} className="title text-dark h5">Amazon</NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>
                    {/* Add more card components here */}
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={Shopify} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                            <NavLink to={`/service/${2}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>

                            </div>
                        </div>
                        <div className="px-2 pt-4">
                            <NavLink to={`/service/${2}`} className="title text-dark h5">Shopify</NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={Etsy} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${3}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                        <NavLink to={`/service/${3}`} className="title text-dark h5">Etsy</NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>
                </Slider>
            </div>
            <div className='container'>
                <h3>Development</h3>
                <Slider {...settings}>
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={WebDev} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${4}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                        <NavLink to={`/service/${4}`}  className="title text-dark h5">Web Development</NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>
                    {/* Add more card components here */}
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={SoftwareDev} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${5}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                        <NavLink to={`/service/${5}`} className="title text-dark h5">Software Development </NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>
                    {/* <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={CSRDev} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${6}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                            <a href="page-single-service.html" className="title text-dark h5">CSR</a>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div> */}
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={AppDev} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${7}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                        <NavLink to={`/service/${7}`} className="title text-dark h5">App Development</NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>
                </Slider>
            </div>
            <div className='container'>
                <h3>Marketing Services</h3>
                <Slider {...settings}>
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={DigitalMarketing} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${8}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                        <NavLink to={`/service/${8}`} className="title text-dark h5">Digital Marketing</NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>
                    {/* Add more card components here */}
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={Seo} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${9}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                        <NavLink to={`/service/${9}`} className="title text-dark h5">SEO Services</NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            {/* <img src="images/blog/05.jpg" className="img-fluid rounded" alt="" /> */}
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               {/* <NavLink to={`/service/${10}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink> */}
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                            {/* <a href="page-single-service.html" className="title text-dark h5">SEO Services</a> */}
                            {/* <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p> */}
                        </div>
                    </div>

                </Slider>
            </div>
            <div className='container'>
                <h3>Call Center Services</h3>
                <Slider {...settings}>
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={CSRServices} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${10}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                        <NavLink to={`/service/${10}`} className="title text-dark h5">CSR Services</NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>
                    {/* Add more card components here */}
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={TruckDispatching} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${11}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                        <NavLink to={`/service/${11}`} className="title text-dark h5">Truck Dispatching</NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={TruckBrokerage} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${12}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                        <NavLink to={`/service/${12}`} className="title text-dark h5">Trucking Brokerage</NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>

                </Slider>
            </div>
            <div className='container'>
                <h3>Designing</h3>
                <Slider {...settings}>
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={WebDesign} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${13}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                        <NavLink to={`/service/${13}`} className="title text-dark h5">Web Designing</NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>
                    {/* Add more card components here */}
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={GraphicDesign} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${14}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                        <NavLink to={`/service/${14}`} className="title text-dark h5">Photoshop/ Ai / Corel Draw / Indesign</NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={Animation} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${15}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                        <NavLink to={`/service/${15}`} className="title text-dark h5">3D Animation Creation</NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={Max} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${16}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                            <NavLink to={`/service/${16}`} className="title text-dark h5">3Ds Max </NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>
                    <div className="card features features-modern feature-primary m-2 p-4" style={{ padding: 25 }}>
                        <div className="card-img position-relative overflow-hidden">
                            <img src={Multimedia} className="img-fluid rounded" alt="" />
                            <div className="overlay rounded" />
                            <div className="content bg-transparent">
                               <NavLink to={`/service/${17}`} className="text-white small mb-0 d-block title">Read More <i className="uil uil-arrow-right align-middle"></i></NavLink>
                            </div>
                        </div>
                        <div className="px-2 pt-4">
                        <NavLink to={`/service/${17}`} className="title text-dark h5">3D Multimedia</NavLink>
                            <p className="text-muted mt-2 mb-0">It contains a series of real Latin words. This ancient dummy text is also incomprehensible.</p>
                        </div>
                    </div>

                </Slider>
            </div>
        </>
    );
};

export default ServicesGrid;
