import React, { useEffect } from 'react';
// import CountUp from 'countup.js';

export default function TeamHome() {
    // useEffect(() => {
    //     const counters = document.querySelectorAll('.counter-value');

    //     counters.forEach(counter => {
    //         const target = +counter.getAttribute('data-target');
    //         const countUp = new CountUp(counter, target, { duration: 2 });

    //         if (!countUp.error) {
    //             countUp.start();
    //         } else {
    //             console.error(countUp.error);
    //         }
    //     });
    // }, []);


    return (
        <>
            <section className="section bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 mb-4 pb-2">
                            <div className="section-title text-center">
                                <h6 className="text-primary">Our Skills</h6>
                                <h4 className="title fw-semibold mt-2 mb-3">We are a team of talented business &amp; investment experts</h4>
                                <p className="text-muted para-desc mx-auto mb-0">Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!</p>
                            </div>
                        </div>{/*end col*/}
                    </div>{/*end row*/}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="progress-box mt-4">
                                <h6 className="text-muted fw-normal">Research</h6>
                                <div className="progress position-relative">
                                    <div className="progress-bar position-relative bg-primary" style={{ width: '84%' }} />
                                    <div className="progress-value d-block text-muted h6 fw-normal">84%</div>
                                </div>
                            </div>{/*end process box*/}
                            <div className="progress-box mt-4">
                                <h6 className="text-muted fw-normal">Sales &amp; Trading</h6>
                                <div className="progress position-relative">
                                    <div className="progress-bar position-relative bg-primary" style={{ width: '75%' }} />
                                    <div className="progress-value d-block text-muted h6 fw-normal">75%</div>
                                </div>
                            </div>{/*end process box*/}
                        </div>{/*end col*/}
                        <div className="col-md-6">
                            <div className="progress-box mt-4">
                                <h6 className="text-muted fw-normal">Investment</h6>
                                <div className="progress position-relative">
                                    <div className="progress-bar position-relative bg-primary" style={{ width: '79%' }} />
                                    <div className="progress-value d-block text-muted h6 fw-normal">79%</div>
                                </div>
                            </div>{/*end process box*/}
                            <div className="progress-box mt-4">
                                <h6 className="text-muted fw-normal">Finance</h6>
                                <div className="progress position-relative">
                                    <div className="progress-bar position-relative bg-primary" style={{ width: '95%' }} />
                                    <div className="progress-value d-block text-muted h6 fw-normal">95%</div>
                                </div>
                            </div>{/*end process box*/}
                        </div>{/*end col*/}
                    </div>{/*end row*/}
                </div>{/*end container*/}

            </section>{/*end section*/}
            {/* End */}

        </>
    );
}
