import React, { useEffect } from 'react';
import worksoulslogo from "../images/workSoulsLogoWhite.png";
import worksoulslogoDark from "../images/workSoulsLogo.png";
import { NavLink, useLocation } from 'react-router-dom';

export default function Footer() {
  const navLinkStyle = {
    color: 'gray',
    textDecoration: 'none'
  };

  const getNavLinkStyle = ({ isActive }) => ({
    color: isActive ? 'white' : 'gray',
    textDecoration: 'none'
  });

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {/* Footer Start */}
      <footer className="footer bg-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-py-60">
                <div className="row">
                  <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                    <a href="/" className="logo-footer">
                      <img src={worksoulslogo} style={{ width: 200 }} className="l-light" alt="Work Souls Logo" />
                    </a>
                    <p className="mt-4">Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!</p>
                  </div>
                  <div className="col-lg-5 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h6 className="footer-head">Company</h6>
                    <ul className="list-unstyled footer-list mt-4">
                      <li>
                        <NavLink to="/" style={({ isActive }) => ({ ...navLinkStyle, ...getNavLinkStyle({ isActive }) })}>
                          Home
                        </NavLink>
                        <span className="menu-arrow" />
                      </li>
                      <li>
                        <NavLink to="/about" style={({ isActive }) => ({ ...navLinkStyle, ...getNavLinkStyle({ isActive }) })}>
                          About
                        </NavLink>
                        <span className="menu-arrow" />
                      </li>
                      <li>
                        <NavLink to="/services" style={({ isActive }) => ({ ...navLinkStyle, ...getNavLinkStyle({ isActive }) })}>
                          Services
                        </NavLink>
                        <span className="menu-arrow" />
                      </li>
                      <li>
                        <NavLink to="/blogs" style={({ isActive }) => ({ ...navLinkStyle, ...getNavLinkStyle({ isActive }) })}>
                          Blogs
                        </NavLink>
                        <span className="menu-arrow" />
                      </li>
                      <li>
                        <NavLink to="/contact" style={({ isActive }) => ({ ...navLinkStyle, ...getNavLinkStyle({ isActive }) })}>
                          Contact Us
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <h6 className="footer-head">Location</h6>
                    <div className="col-12 p-0">
                      <div className="card map border-0">
                        <div className="card-body p-0">
                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" style={{ border: 0, height: 200 }} allowFullScreen></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-py-30 footer-bar bg-footer">
          <div className="container">
            <div className="row align-items-center">
              <div className="col">
                <div className="text-center">
                  <p className="mb-0 text-muted">© 2024 Work Souls Group. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer End */}
    </>
  );
}
