import React from 'react'
import Header from './Header'
import Footer from './Footer'
import AboutHero from './AboutHero'
import AboutContent from './AboutContent'

export default function About() {
  return (
    <div>
        <AboutHero/>
        <AboutContent/>
</div>
  )
}
