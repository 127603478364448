import React from 'react'
import { NavLink } from 'react-router-dom';

export default function Contact() {
    return (
        <>
            <div>
            <section className="bg-half-170 d-table w-100" style={{ background: 'center center', backgroundImage: `url(${require('../images/bg/services.jpg')})` }} data-background="../images/bg/services.jpg">
                <div className="bg-overlay bg-gradient-overlay" />
                <div className="container">
                    <div className="row g-0 align-items-center mt-5">
                        <div className="col-lg-8 col-md-6">
                            <div className="title-heading text-md-start text-center">
                                <p className="text-white-50 mb-0">GET IN TOUCH</p>
                                <h5 className="heading fw-semibold page-heading text-white title-dark">Contact Us</h5>
                            </div>
                        </div>{/*end col*/}
                        <div className="col-lg-4 col-md-6 mt-4 mt-sm-0">
                            <div className="text-md-end text-center">
                                <nav aria-label="breadcrumb" className="d-inline-block">
                                    <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                        <li className="breadcrumb-item"><NavLink to="/">Work Souls Group</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Contact</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>{/*end row*/}
                </div>{/*end container*/}
            </section>{/*end section*/}
            {/* Hero End */}
                {/* Hero End */}
                {/* Start */}
                <section className="section pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card border-0 text-center features feature-clean bg-transparent">
                                    <div className="icons text-primary text-center mx-auto">
                                        <i className="uil uil-phone d-block rounded h3 mb-0" />
                                    </div>
                                    <div className="content mt-3">
                                        <h5 className="footer-head">Phone</h5>
                                        <p className="text-muted">Start working with Work Souls Group that can provide everything</p>
                                        <a href="tel:+152534-468-854" className="text-foot">            
                                            042, 34551186</a>
                                    </div>
                                </div>
                            </div>{/*end col*/}
                            <div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="card border-0 text-center features feature-clean bg-transparent">
                                    <div className="icons text-primary text-center mx-auto">
                                        <i className="uil uil-envelope d-block rounded h3 mb-0" />
                                    </div>
                                    <div className="content mt-3">
                                        <h5 className="footer-head">Email</h5>
                                        <p className="text-muted">Start working with Work Souls Group that can provide everything</p>
                                        <a href="mailto:info@worksoulsgroup.com" className="text-foot">info@worksoulsgroup.com</a>
                                    </div>
                                </div>
                            </div>{/*end col*/}
                            <div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="card border-0 text-center features feature-clean bg-transparent">
                                    <div className="icons text-primary text-center mx-auto">
                                        <i className="uil uil-map-marker d-block rounded h3 mb-0" />
                                    </div>
                                    <div className="content mt-3">
                                        <h5 className="footer-head">Location</h5>
                                        <p className="text-muted">8-E/3, main boulevard, Firdous Mkt Rd, opp. Centre Point Plaza, Gulberg III, Lahore
</p>
                                        <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" data-type="iframe" className="video-play-icon text-foot lightbox">View on Google map</a>
                                    </div>
                                </div>
                            </div>{/*end col*/}
                        </div>{/*end row*/}
                    </div>{/*end container*/}
                    <div className="container mt-100 mt-60">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="section-title mb-5 pb-2 text-center">
                                    <h4 className="title mb-3">Get In Touch !</h4>
                                    <p className="text-muted para-desc mx-auto mb-0">Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!</p>
                                </div>
                                <div className="custom-form">
                                    <form method="post" name="myForm" id="myForm" onsubmit="return validateForm()">
                                        <p id="error-msg" className="mb-0" />
                                        <div id="simple-msg" />
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Your Name <span className="text-danger">*</span></label>
                                                    <input name="name" id="name" type="text" className="form-control" placeholder="Name :" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                                    <input name="email" id="email" type="email" className="form-control" placeholder="Email :" />
                                                </div>
                                            </div>{/*end col*/}
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Subject</label>
                                                    <input name="subject" id="subject" className="form-control" placeholder="Subject :" />
                                                </div>
                                            </div>{/*end col*/}
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Comments <span className="text-danger">*</span></label>
                                                    <textarea name="comments" id="comments" rows={4} className="form-control" placeholder="Message :" defaultValue={""} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-grid">
                                                    <button type="submit" id="submit" name="send" className="btn btn-primary">Send Message</button>
                                                </div>
                                            </div>{/*end col*/}
                                        </div>{/*end row*/}
                                    </form>
                                </div>{/*end custom-form*/}
                            </div>{/*end col*/}
                        </div>{/*end row*/}
                    </div>{/*end container*/}
                    <div className="container-fluid mt-100 mt-60">
                        <div className="row">
                            <div className="col-12 p-0">
                                <div className="card map border-0">
                                    <div className="card-body p-0">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" style={{ border: 0 }} allowFullScreen />
                                    </div>
                                </div>
                            </div>{/*end col*/}
                        </div>{/*end row*/}
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* End */}
            </div>

        </>
    )
}
