import React from 'react'

export default function AboutContent() {
    return (
        <>
            {/* Start */}
            <section className="section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <img src="images/about.jpg" className="img-fluid rounded shadow" alt />
                        </div>{/*end col*/}
                        <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className="section-title ms-lg-5">
                                <h4 className="title mb-3">We Are A Creative IT Company!</h4>
                                <p className="text-muted">Welcome to Worksouls, your destination for comprehensive digital solutions. Specializing in e-commerce expertise, web design, and app development, we ensure your online presence thrives. With a focus on results, our services span digital marketing, call center support, and logistics optimization. Our creative team adds visual flair with graphic design and 3D animation, elevating your brand. Partner with Worksouls and propel your business forward in the digital landscape.</p>
                                <ul className="list-unstyled text-muted mb-0">
                                    <li className="mb-0"><span className="text-dark h5 me-2"><i className="uil uil-check-circle align-middle" /></span>Expertise in e-commerce, web design, and app development</li>
                                    <li className="mb-0"><span className="text-dark h5 me-2"><i className="uil uil-check-circle align-middle" /></span>Results-driven digital marketing strategies</li>
                                    <li className="mb-0"><span className="text-dark h5 me-2"><i className="uil uil-check-circle align-middle" /></span>Efficient call center support for seamless customer communication</li>
                                    <li className="mb-0"><span className="text-dark h5 me-2"><i className="uil uil-check-circle align-middle" /></span>Optimized logistics solutions for streamlined operations</li>
                                    <li className="mb-0"><span className="text-dark h5 me-2"><i className="uil uil-check-circle align-middle" /></span>Creative graphic design and 3D animation services</li>
                                    <li className="mb-0"><span className="text-dark h5 me-2"><i className="uil uil-check-circle align-middle" /></span>Comprehensive digital solutions tailored to your needs</li>

                                </ul>
                            </div>
                        </div>{/*end col*/}
                    </div>{/*end row*/}
                </div>{/*end container*/}
                <div className='container' style={{ paddingTop: 50 }}>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 mt-5 pt-2 mt-sm-0 pt-sm-0" style={{ paddingTop: 30 }}>
                            <div className="section-title ms-lg-5">
                                <h4 className="title mb-3" style={{ textAlign: 'center' }}>Our Vision</h4>
                                <p className="text-muted" style={{ textAlign: "center" }}>At Worksouls, our vision is to redefine the digital landscape through pioneering solutions that empower businesses to flourish in today's dynamic market. We envisage a future where technology serves as a catalyst for growth, efficiency, and prosperity across diverse industries. With unwavering dedication to excellence, integrity, and relentless innovation, we aspire to be the trusted ally and driving force behind digital transformation. Together, we endeavor to forge a world where every enterprise is equipped with the resources and assistance necessary to realize their ambitions and leave an enduring imprint in the digital age.</p>
                            </div>
                        </div>{/*end col*/}
                    </div>
                </div>
                <div className="container-fluid px-0 mt-100 mt-60">
                    <div className="bg-half-260" style={{ background: 'url("images/cta.jpg") top' }}>
                        <div className="bg-overlay" />
                        <div className="play-icon">
                            <a href="javascript:void(0)" data-type="youtube" data-id="yba7hPeTSjk" className="play-btn lightbox">
                                <i className="mdi mdi-play text-primary rounded-circle bg-white shadow" />
                            </a>
                        </div>
                    </div>
                </div>{/*end container*/}


                {/* Partners start */}
                {/* 
<div className="container mt-5">
  <div className="row justify-content-center">
    <div className="col-lg-2 col-md-2 col-6 text-center">
      <img src="images/client/logo-1.svg" className="img-fluid" alt />
    </div> */}
                {/*end col*/}
                {/* <div className="col-lg-2 col-md-2 col-6 text-center"> */}
                {/* <img src="images/client/logo-2.svg" className="img-fluid" alt /> */}
                {/* </div> */}
                {/*end col*/}
                {/* <div className="col-lg-2 col-md-2 col-6 text-center"> */}
                {/* <img src="images/client/logo-3.svg" className="img-fluid" alt /> */}
                {/* </div> */}
                {/*end col*/}
                {/* <div className="col-lg-2 col-md-2 col-6 text-center"> */}
                {/* <img src="images/client/logo-4.svg" className="img-fluid" alt /> */}
                {/* </div> */}
                {/*end col*/}
                {/* <div className="col-lg-2 col-md-2 col-6 text-center"> */}
                {/* <img src="images/client/logo-5.svg" className="img-fluid" alt /> */}
                {/* </div> */}
                {/*end col*/}
                {/* <div className="col-lg-2 col-md-2 col-6 text-center"> */}
                {/* <img src="images/client/logo-6.svg" className="img-fluid" alt /> */}
                {/* </div> */}
                {/*end col*/}
                {/* </div> */}
                {/*end row*/}
                {/* </div> */}
                {/*end container*/}
                {/* Partners End */}

                {/* <section className="section bg-light">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 mb-4 pb-2">
        <div className="section-title text-center">
          <h6 className="text-primary">Our Skills</h6>
          <h4 className="title fw-semibold mt-2 mb-3">We are a team of talented business &amp; investment experts</h4>
          <p className="text-muted para-desc mx-auto mb-0">Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!</p>
        </div>
      </div>
      {/*end col*/}
                {/* </div> */}
                {/*end row
  </div>*/}

                {/*end container*/}
                {/* //   <div className="container-fluid mt-100 mt-60">
//     <div className="row">
//       {/* Team Member 1 */}
                {/* <div className="col-lg-2 col-md-6 mb-4">         <div className="card team team-primary">           <div className="card-img">             <img src="images/client/09.jpg" className="img-fluid" alt="Dennis Rosario" />             <div className="card-overlay" />           </div>           <div className="team-content">             <a href="javascript:void(0)" className="h6 name text-uppercase d-block mb-0 text-white title-dark">Dennis Rosario</a>             <small className="text-white title-dark">C.E.O</small>           </div>         </div>       </div> */}
                {/* Team Member 2 */}
                {/* <div className="col-lg-2 col-md-6 mb-4">
        <div className="card team team-primary">
          <div className="card-img">
            <img src="images/client/10.jpg" className="img-fluid" alt="Billy Gregory" />
            <div className="card-overlay" />
          </div>
          <div className="team-content">
            <a href="javascript:void(0)" className="h6 name text-uppercase d-block mb-0 text-white title-dark">Billy Gregory</a>
            <small className="text-white title-dark">Manager</small>
          </div>
        </div>
      </div>
      <div className="col-lg-2 col-md-6 mb-4">
        <div className="card team team-primary">
          <div className="card-img">
            <img src="images/client/10.jpg" className="img-fluid" alt="Billy Gregory" />
            <div className="card-overlay" />
          </div>
          <div className="team-content">
            <a href="javascript:void(0)" className="h6 name text-uppercase d-block mb-0 text-white title-dark">Billy Gregory</a>
            <small className="text-white title-dark">Manager</small>
          </div>
        </div>
      </div>
      <div className="col-lg-2 col-md-6 mb-4">
        <div className="card team team-primary">
          <div className="card-img">
            <img src="images/client/10.jpg" className="img-fluid" alt="Billy Gregory" />
            <div className="card-overlay" />
          </div>
          <div className="team-content">
            <a href="javascript:void(0)" className="h6 name text-uppercase d-block mb-0 text-white title-dark">Billy Gregory</a>
            <small className="text-white title-dark">Manager</small>
          </div>
        </div>
      </div>
      <div className="col-lg-2 col-md-6 mb-4">
        <div className="card team team-primary">
          <div className="card-img"> */}
                {/* <img src="images/client/10.jpg" className="img-fluid" alt="Billy Gregory" />
            <div className="card-overlay" />
          </div>
          <div className="team-content">
            <a href="javascript:void(0)" className="h6 name text-uppercase d-block mb-0 text-white title-dark">Billy Gregory</a>
            <small className="text-white title-dark">Manager</small>
          </div>
        </div>
      </div>
      <div className="col-lg-2 col-md-6 mb-4">
        <div className="card team team-primary">
          <div className="card-img">
            <img src="images/client/10.jpg" className="img-fluid" alt="Billy Gregory" />
            <div className="card-overlay" />
          </div>
          <div className="team-content">
            <a href="javascript:void(0)" className="h6 name text-uppercase d-block mb-0 text-white title-dark">Billy Gregory</a>
            <small className="text-white title-dark">Manager</small>
          </div>
        </div>
      </div>
    </div>
  </div> */}
                {/* // </section>  */}

                {/*end section*/}
                {/* End */}
                <div className="container mt-100 mt-60">
                    <div className="row">
                        <div className="col-md-3 col-6">
                            <div className="counter-box position-relative text-center">
                                <h2 className="mb-0 display-1 fw-bold title-dark mt-2 opacity-05"><span className="counter-value" data-target={5458}>3344</span></h2>
                                <span className="counter-head fw-semibold title-dark position-absolute top-50 start-50 translate-middle">Investment Projects</span>
                            </div>{/*end counter box*/}
                        </div>{/*end col*/}
                        <div className="col-md-3 col-6">
                            <div className="counter-box position-relative text-center">
                                <h2 className="mb-0 display-1 fw-bold title-dark mt-2 opacity-05"><span className="counter-value" data-target={15}>04</span></h2>
                                <span className="counter-head fw-semibold title-dark position-absolute top-50 start-50 translate-middle">Years of Experience</span>
                            </div>{/*end counter box*/}
                        </div>{/*end col*/}
                        <div className="col-md-3 col-6">
                            <div className="counter-box position-relative text-center">
                                <h2 className="mb-0 display-1 fw-bold title-dark mt-2 opacity-05"><span className="counter-value" data-target={54}>03</span></h2>
                                <span className="counter-head fw-semibold title-dark position-absolute top-50 start-50 translate-middle">Offices in the World</span>
                            </div>{/*end counter box*/}
                        </div>{/*end col*/}
                        <div className="col-md-3 col-6">
                            <div className="counter-box position-relative text-center">
                                <h2 className="mb-0 display-1 fw-bold title-dark mt-2 opacity-05"><span className="counter-value" data-target={214}>354</span></h2>
                                <span className="counter-head fw-semibold title-dark position-absolute top-50 start-50 translate-middle">Successful Cases</span>
                            </div>{/*end counter box*/}
                        </div>{/*end col*/}
                    </div>{/*end row*/}
                </div>
                {/*end container*/}
            </section>{/*end section*/}
            {/* End */}

        </>
    )
}
