import React from 'react'

export default function FeatureHome() {
    return (
        <>

            {/* Features Start */}
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 order-1 order-md-2">
                            <div className="features-absolute">
                                <div className="position-relative">
                                    <img src="images/01.jpg" className="img-fluid rounded shadow" alt />
                                    <div className="play-icon">
                                        <a href="javascript:void(0)" data-type="youtube" data-id="yba7hPeTSjk" className="play-btn lightbox">
                                            <i className="mdi mdi-play text-primary rounded-circle bg-white shadow" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>{/*end col*/}
                        <div className="col-lg-7 col-md-6 order-2 order-md-1">
                            <div className="row">
                                <div className="col-lg-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <div className="card features feature-primary">
                                        <i className="uil uil-analytics icon h1 d-block" />
                                        <a href="page-single-service.html" className="h5 title text-dark">Business Analysis</a>
                                        <p className="text-muted mt-2 mb-0">Due to its widespread use as filler text for layouts, non-readability is of great importance</p>
                                    </div>
                                </div>{/*end col*/}
                                <div className="col-lg-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                    <div className="card features feature-primary">
                                        <i className="uil uil-receipt icon h1 d-block" />
                                        <a href="page-single-service.html" className="h5 title text-dark">Estate Planning</a>
                                        <p className="text-muted mt-2 mb-0">Due to its widespread use as filler text for layouts, non-readability is of great importance</p>
                                    </div>
                                </div>{/*end col*/}
                                <div className="col-lg-6 col-12 mt-4 pt-2">
                                    <div className="card features feature-primary">
                                        <i className="uil uil-bitcoin-circle icon h1 d-block" />
                                        <a href="page-single-service.html" className="h5 title text-dark">Cryptocurrency</a>
                                        <p className="text-muted mt-2 mb-0">Due to its widespread use as filler text for layouts, non-readability is of great importance</p>
                                    </div>
                                </div>{/*end col*/}
                                <div className="col-lg-6 col-12 mt-4 pt-2">
                                    <div className="card features feature-primary">
                                        <i className="uil uil-moneybag-alt icon h1 d-block" />
                                        <a href="page-single-service.html" className="h5 title text-dark">Security Enhanced</a>
                                        <p className="text-muted mt-2 mb-0">Due to its widespread use as filler text for layouts, non-readability is of great importance</p>
                                    </div>
                                </div>{/*end col*/}
                            </div>{/*end row*/}
                        </div>{/*end col*/}
                    </div>{/*end row*/}
                </div>{/*end container*/}
                {/* Partners start */}
                {/* <div className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-2 col-md-2 col-6 text-center py-4">
                            <img src="images/client/logo-1.svg" className="img-fluid" alt />
                        </div>{/*end col*/}
                        {/* <div className="col-lg-2 col-md-2 col-6 text-center py-4"> */}
                            {/* <img src="images/client/logo-2.svg" className="img-fluid" alt /> */}
                        {/* </div>end col */}
                        {/* <div className="col-lg-2 col-md-2 col-6 text-center py-4"> */}
                            {/* <img src="images/client/logo-3.svg" className="img-fluid" alt /> */}
                        {/* </div>end col */}
                        {/* <div className="col-lg-2 col-md-2 col-6 text-center py-4"> */}
                            {/* <img src="images/client/logo-4.svg" className="img-fluid" alt /> */}
                        {/* </div>end col */}
                        {/* <div className="col-lg-2 col-md-2 col-6 text-center py-4"> */}
                            {/* <img src="images/client/logo-5.svg" className="img-fluid" alt /> */}
                        {/* </div>end col */}
                        {/* <div className="col-lg-2 col-md-2 col-6 text-center py-4"> */}
                            {/* <img src="images/client/logo-6.svg" className="img-fluid" alt /> */}
                        {/* </div>end col */}
                    {/* </div>end row */}
                {/* </div>end container */}
                {/* Partners End */}
            </section>{/*end section*/}
            {/* Features End */}


        </>
    )
}
