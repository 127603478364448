import React, { useEffect } from 'react';
import Swiper from 'swiper';
import { NavLink } from 'react-router-dom';

// import 'swiper/swiper-bundle.min.css'; // Import Swiper styles

export default function Hero() {
    useEffect(() => {
        const swiper = new Swiper('.swiper-container', {
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }, []);


    return (
        <>
            {/* Hero Start */}
            <section className="swiper-slider-hero position-relative d-block vh-100">
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        {/* Slide 1 */}
                        <div className="swiper-slide d-flex align-items-center overflow-hidden">
                            <div className="slide-inner slide-bg-image d-flex align-items-center" data-jarallax={{ speed: 0.5 }} style={{ background: 'center center', backgroundImage: `url(${require('../images/bg/1.png')})` }} data-background="../images/bg/1.png">
                                <div className="bg-overlay bg-linear-gradient" />
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-12">
                                            <div className="title-heading text-center">
                                                <h1 className="fw-semibold display-3 text-white title-dark mb-4">Build and Grow Your Business</h1>
                                                <p className="para-desc mx-auto text-white-50">Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!</p>
                                                <div className="mt-4 pt-2">
                                                    <NavLink to="/services" className="btn btn-primary">See Services</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Slide 2 */}
                        <div className="swiper-slide d-flex align-items-center overflow-hidden">
                            <div className="slide-inner slide-bg-image d-flex align-items-center" data-jarallax={{ speed: 0.5 }} style={{ background: 'center center', backgroundImage: `url(${require('../images/bg/2.png')})` }} data-background="../images/bg/2.png">
                                <div className="bg-overlay bg-linear-gradient" />
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-12">
                                            <div className="title-heading text-center">
                                                <h1 className="fw-semibold display-3 text-white title-dark mb-4">Take Care of Your Future</h1>
                                                <p className="para-desc mx-auto text-white-50">Empower your future with us. Discover innovative solutions for your business success.</p>
                                                <div className="mt-4 pt-2">
                                                    <NavLink to="/about" className="btn btn-primary">Get Started</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Slide 3 */}
                        <div className="swiper-slide d-flex align-items-center overflow-hidden">
                            <div className="slide-inner slide-bg-image d-flex align-items-center" data-jarallax={{ speed: 0.5 }} style={{ background: 'center center', backgroundImage: `url(${require('../images/bg/3.png')})` }} data-background="../images/bg/3.png">
                                <div className="bg-overlay bg-linear-gradient" />
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-12">
                                            <div className="title-heading text-center">
                                                <h1 className="fw-semibold display-3 text-white title-dark mb-4">Let's Start With Work Souls Group</h1>
                                                <p className="para-desc mx-auto text-white-50">Empower your workforce with Work Souls Group. Elevate productivity and collaboration.</p>
                                                <div className="mt-4 pt-2">
                                                    <NavLink to="/contact" className="btn btn-primary">Contact us</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End swiper-wrapper */}
                    {/* Swiper controls */}
                    {/* <div className="swiper-button-next border rounded-circle text-center" />
                    <div className="swiper-button-prev border rounded-circle text-center" /> */}
                </div>
                {/* End swiper-container */}
            </section>
            {/* End section */}
            {/* Hero End */}
        </>
    );
}
