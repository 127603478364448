import React from 'react'
import ServicesHero from './ServicesHero'
import ServicesGrid from './ServicesGrid'

export default function Services() {
  return (
    <div>
        <ServicesHero/>
        <ServicesGrid/>
    </div>
  )
}
