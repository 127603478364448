import React, { useEffect } from 'react';
import Swiper from 'swiper';

export default function TestimonialsHome() {
    useEffect(() => {
        const swiper = new Swiper('.swiper-container', {
            slidesPerView: 'auto',
            loop: true,
            autoplay: {
                delay: 3000,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }, []);
    return (
        <>
            {/* Start */}
            <section className="section">
            <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <div className="swiper-container tiny-single-item">
                        <div className="swiper-wrapper">
                            {/* Slide 1 */}
                            <div className="swiper-slide px-md-5">
                                <div className="card client-testi text-center">
                                    <img src="images/client/01.jpg" className="avatar avatar-small rounded-pill mx-auto" alt="" />
                                    <div className="card-body pb-0 content">
                                        <p className="h5 fw-normal text-muted fst-italic">" The advantage of its Latin origin and the relative meaninglessness of Lorum Ipsum is that the text does not attract attention to itself or distract the viewer's attention from the layout. "</p>
                                        <div className="name mt-4">
                                            <small className="text-uppercase fw-semibold d-block">Johnny Rosario</small>
                                            <small className="text-muted">C.E.O</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Slide 2 */}
                            <div className="swiper-slide px-md-5">
                                <div className="card client-testi text-center">
                                    <img src="images/client/02.jpg" className="avatar avatar-small rounded-pill mx-auto" alt="" />
                                    <div className="card-body pb-0 content">
                                        <p className="h5 fw-normal text-muted fst-italic">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others - which creates a distinct visual impression. "</p>
                                        <div className="name mt-4">
                                            <small className="text-uppercase fw-semibold d-block">Gale Larose</small>
                                            <small className="text-muted">Manager</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Slide 3 */}
                            <div className="swiper-slide px-md-5">
                                <div className="card client-testi text-center">
                                    <img src="images/client/03.jpg" className="avatar avatar-small rounded-pill mx-auto" alt="" />
                                    <div className="card-body pb-0 content">
                                        <p className="h5 fw-normal text-muted fst-italic">" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. If the fill text is intended to illustrate the characteristics of different typefaces. "</p>
                                        <div className="name mt-4">
                                            <small className="text-uppercase fw-semibold d-block">Shelly Goodman</small>
                                            <small className="text-muted">Manager</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div>
                <div className="container mt-100 mt-60">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h6 className="text-primary">Blogs</h6>
                                <h4 className="title fw-semibold mt-2 mb-3">Latest Blog and News</h4>
                                <p className="text-muted para-desc mx-auto mb-0">Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!</p>
                            </div>
                        </div>{/*end col*/}
                    </div>{/*end row*/}
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 mt-4 pt-2">
                            <div className="card blog blog-image blog-primary shadow rounded overflow-hidden">
                                <div className="card-img">
                                    <img src="images/blog/img01.jpg" className="img-fluid" alt />
                                    <div className="card-overlay" />
                                    <div className="position-absolute top-0 start-0 mt-3 ms-4">
                                        <a href="javascript:void(0)" className="badge text-bg-light">Finance</a>
                                    </div>
                                </div>
                                <div className="content px-4">
                                    <a href="blog-detail-one.html" className="h5 title d-block mb-0 text-white title-dark">How to Get a Successful Ad for Your Business</a>
                                    <div className="d-flex author align-items-center mt-2">
                                        <img src="images/client/04.jpg" className="avatar avatar-md-sm rounded-pill" alt />
                                        <div className="ms-2">
                                            <small className="text-light title-dark user d-block"> Calvin Carlo</small>
                                            <small className="text-light title-dark date"> 13th April 2021</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/*end col*/}
                        <div className="col-lg-4 col-md-6 mt-4 pt-2">
                            <div className="card blog blog-primary shadow rounded overflow-hidden">
                                <div className="image position-relative overflow-hidden">
                                    <img src="images/blog/01.jpg" className="img-fluid" alt />
                                    <div className="blog-tag">
                                        <a href="javascript:void(0)" className="badge text-bg-light">Corporate</a>
                                    </div>
                                </div>
                                <div className="card-body content">
                                    <a href="blog-detail-three.html" className="h5 title text-dark d-block mb-0">Building Your Corporate Identity from Starty</a>
                                    <p className="text-muted mt-2 mb-2">The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.</p>
                                    <a href="blog-detail-three.html" className="link text-dark">Read More <i className="uil uil-arrow-right align-middle" /></a>
                                </div>
                            </div>
                        </div>{/*end col*/}
                        <div className="col-lg-4 col-md-6 mt-4 pt-2">
                            <div className="card blog blog-primary shadow rounded overflow-hidden">
                                <div className="image position-relative overflow-hidden">
                                    <img src="images/blog/02.jpg" className="img-fluid" alt />
                                    <div className="blog-tag">
                                        <a href="javascript:void(0)" className="badge text-bg-light">Business</a>
                                    </div>
                                </div>
                                <div className="card-body content">
                                    <a href="blog-detail-three.html" className="h5 title text-dark d-block mb-0">Stuck in a Financial Debt? Here’s an Easy Way Out</a>
                                    <p className="text-muted mt-2 mb-2">The most well-known dummy text is the 'Lorem Ipsum', in the 16th century.</p>
                                    <a href="blog-detail-three.html" className="link text-dark">Read More <i className="uil uil-arrow-right align-middle" /></a>
                                </div>
                            </div>
                        </div>{/*end col*/}
                    </div>{/*end row*/}
                </div>{/*end container*/}
            </section>{/*end section*/}
            {/* End */}


        </>
    )
}
