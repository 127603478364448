import React from 'react'
import { NavLink } from 'react-router-dom';

export default function ServicesHero() {
    return (
        <>
            {/* Hero Start */}
            <section className="bg-half-170 d-table w-100" style={{ background: 'center center', backgroundImage: `url(${require('../images/bg/services.jpg')})` }} data-background="../images/bg/services.jpg">
                <div className="bg-overlay bg-gradient-overlay" />
                <div className="container">
                    <div className="row g-0 align-items-center mt-5">
                        <div className="col-lg-8 col-md-6">
                            <div className="title-heading text-md-start text-center">
                                <h5 className="heading fw-semibold page-heading text-white title-dark">Our Services</h5>
                                <p className="text-white-50 mb-0">We Are Here To Help You</p>
                            </div>
                        </div>{/*end col*/}
                        <div className="col-lg-4 col-md-6 mt-4 mt-sm-0">
                            <div className="text-md-end text-center">
                                <nav aria-label="breadcrumb" className="d-inline-block">
                                    <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                        <li className="breadcrumb-item"><NavLink to="/">Work Souls Group</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Services</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>{/*end row*/}
                </div>{/*end container*/}
            </section>{/*end section*/}
            {/* Hero End */}

        </>
    )
}
