import React from 'react';
import { useParams } from 'react-router-dom';
import ServicesPageContent from './ServicesPageContent';
import '../Components/StyleFiles/Services.css'
import { NavLink } from 'react-router-dom';


export default function SingleServicePage() {
    let { serviceId } = useParams();

    // Importing the services array from ServicesGrid
    const services = ServicesPageContent();

    // Find the service with the matching ID
    const service = services.find(service => service.id === parseInt(serviceId));

    return (
        <>
            <div>
                {/* Hero Start */}
                <section className="bg-half-80 d-table bg-primary bg-gradient w-100" style={{ backgroundImage: `url(${service.image})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>


                    <div className="bg-overlay bg-gradient-overlay" />
                    <div className="container">
                        <div className="row g-0 align-items-center mt-5">
                            <div className="col-lg-8 col-md-6">
                                <div className="title-heading text-md-start text-center">
                                    <h5 className="heading fw-semibold page-heading text-white title-dark">{service.name}</h5>
                                    <p className="text-white-50 mb-0">Walking is the best possible exercise</p>
                                </div>
                            </div>{/*end col*/}
                            <div className="col-lg-4 col-md-6 mt-4 mt-sm-0">
                                <div className="text-md-end text-center">
                                    <nav aria-label="breadcrumb" className="d-inline-block">
                                        <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                            <li className="breadcrumb-item"><NavLink to="/">Work Souls Group</NavLink></li>
                                            <li className="breadcrumb-item"><NavLink to="/services">Services</NavLink></li>
                                            <li className="breadcrumb-item active" aria-current="page">{service.name}</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>{/*end row*/}
                    </div>{/*end container*/}
                </section>{/*end section*/}
                {/* Hero End */}
                {/* Start */}
                <section className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-6">
                                <div className="section-title">
                                    <img src={service.image} className="img-fluid rounded" alt />
                                    <h4
  className="mt-4 postPageTitle"
  id="postPageTitleId"
  style={{
    fontWeight: 700,
    fontSize: 23,
    color: '#1B2E55',
    important: 'true' // Adding important as a string value
  }}
>
  {service.title}
</h4>



                                    <p className="text-muted mt-4">{service.description}</p>


                                </div>
                            </div>{/*end col*/}
                            <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="sidebar sticky-bar">
                                    {/* Author */}
                                    <div className="widget text-center position-relative">
                                        <div className="rounded shadow bg-white">
                                            <div className="position-relative">
                                                <img src="images/blog/12.jpg" className="img-fluid rounded shadow" alt />
                                                <div className="play-icon">
                                                    <a href="javascript:void(0)" data-type="youtube" data-id="yba7hPeTSjk" className="play-btn lightbox">
                                                        <i className="mdi mdi-play text-primary rounded-circle bg-white shadow" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Author */}
                                    {/* Subscribe */}
                                    <div className="widget mt-4 pt-2">
                                        <div className="rounded p-4 shadow bg-white">
                                            <h6 className="widget-title font-weight-bold pt-2 pb-2 shadow bg-light rounded text-center">Subscribe</h6>
                                            <form className="mt-4">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="foot-subscribe mb-3">
                                                            <label className="form-label">Write your email <span className="text-danger">*</span></label>
                                                            <input type="email" name="email" id="emailsubscribe" className="form-control rounded" placeholder="Your email : " required />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <input type="submit" id="submitsubscribe" name="send" className="btn btn-primary w-100" defaultValue="Subscribe" />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* Subscribe */}
                                </div>
                            </div>{/*end col*/}
                        </div>{/*end row*/}
                    </div>{/*end container*/}
                </section > {/*end section*/}
                {/* End */}
            </div >

        </>
    );
}
